<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-center justify-space-between"
      style="min-height: 36px"
    >
      <table-name
        :table-number="tableNumber"
        :table-prefix="tablePrefix"
        :table-suffix="tableSuffix"
        class="text-xs mb-1"
      />

      <div
        v-if="addedCustomerTags.length > 0"
        class="d-flex align-center"
      >
        <v-icon
          left
          small
        >
          {{ icons.mdiPound }}
        </v-icon>

        <div style="max-width: 240px">
          <v-chip-group>
            <v-chip
              v-for="(customerTag) in addedCustomerTags"
              :key="`${tablePrefix}${tableNumber}${tableSuffix}-customerTag${customerTag.id}`"
              small
            >
              {{ customerTag.attributes.name }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </div>

    <v-card
      class="elevation-4"
      min-height="236.5"
      :ripple="false"
      @click="$emit('clicked')"
    >
      <table-timer
        :start-at="startAt"
        :expire-at="expireAt"
        class="py-4 px-3"
      />
      <v-divider></v-divider>

      <v-card-text
        class="d-flex flex-grow-1 flex-column justify-space-between"
      >
        <div class="d-flex align-center justify-space-between">
          <div>
            <v-badge
              :content="customerCount"
              inline
            >
              <v-icon
                large
              >
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-badge>
          </div>

          <div class="d-flex">
            <sup>
              <v-icon>
                {{ icons.mdiCurrencyJpy }}
              </v-icon>
            </sup>
            <span
              class="text-2xl font-weight-semibold"
            >
              {{ total.toLocaleString() }}
            </span>
          </div>
        </div>

        <div
          class="d-flex align-center"
          style="min-height: 36px"
        >
          <v-icon
            left
            small
          >
            {{ icons.mdiVectorLink }}
          </v-icon>
          <v-chip-group
            v-if="referringHistories.length > 0"
            column
          >
            <span
              v-for="referringHistory in referringHistories"
              :key="`referringHistory${referringHistory.id}`"
            >
              {{ referringHistory.attributes.user.data.attributes.name }}
              <v-chip small>
                {{ referringHistory.attributes.referral.data.attributes.name }}
              </v-chip>
            </span>
          </v-chip-group>

          <span v-else>---</span>
        </div>

        <nomination-display
          :nomination-histories="currentNominations"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { map } from 'lodash'
import {
  mdiAccountOutline,
  mdiCurrencyJpy,
  mdiVectorLink,
  mdiPound,
} from '@mdi/js'
import TableName from '@/views/components/table/TableName.vue'
import TableTimer from '@/views/components/table/TableTimer.vue'
import NominationDisplay from '@/views/components/table/NominationDisplay.vue'

export default {
  components: {
    TableName,
    TableTimer,
    NominationDisplay,
  },
  props: {
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    tableNumber: {
      type: [String, Number],
      required: true,
    },
    startAt: {
      type: String,
      required: true,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
    customerCount: {
      type: [Number, String],
      required: true,
    },
    subtotal: {
      type: [Number, String],
      required: true,
    },
    total: {
      type: [Number, String],
      required: true,
    },
    currentNominations: {
      // NOTE: 中身はNominationだけのvending_history
      type: Array,
      default: () => [],
    },
    referringHistories: {
      type: Array,
      default: () => [],
    },
    customerTableTaggings: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const addedCustomerTags = computed(() => {
      if (props.customerTableTaggings.length < 1) return []

      return map(props.customerTableTaggings, 'attributes.customerTag.data')
    })

    return {
      // computed
      addedCustomerTags,

      icons: {
        mdiAccountOutline,
        mdiCurrencyJpy,
        mdiVectorLink,
        mdiPound,
      },
    }
  },
}
</script>
