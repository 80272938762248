import { render, staticRenderFns } from "./CourseHistories.vue?vue&type=template&id=40c6288c&scoped=true&"
import script from "./CourseHistories.vue?vue&type=script&lang=js&"
export * from "./CourseHistories.vue?vue&type=script&lang=js&"
import style0 from "./CourseHistories.vue?vue&type=style&index=0&id=40c6288c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c6288c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VExpandTransition,VIcon,VSpacer,VTimeline,VTimelineItem})
