<template>
  <v-card
    v-if="referrals.length > 0"
    elevation="0"
  >
    <v-card-title>
      <p class="pa-0 ma-0 text--secondary text-sm">
        {{ currentClub.referralAlias }}
      </p>
      <v-spacer />
      <v-btn
        icon
        fab
        :ripple="false"
        @click="opener = !opener"
      >
        <v-icon>
          {{ icons.mdiCogOutline }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <v-card-text v-show="!opener">
        <div v-if="referringHistories.length > 0">
          <v-list-item
            v-for="(referringHistory, referringHistoryIdx) in referringHistories"
            :key="`referringHistories[${referringHistoryIdx}]`"
            dense
            class="pl-1"
          >
            <v-list-item-avatar
              size="20"
              class="mr-2"
            >
              <v-img
                v-if="referringHistory.attributes.user.data.attributes.profileImage"
                :src="referringHistory.attributes.user.data.attributes.profileImage"
              />
              <v-icon v-else>
                {{ icons.mdiAccountCircleOutline }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content
              class="ma-0 pa-0"
            >
              <v-list-item-title>
                {{ referringHistory.attributes.user.data.attributes.name }}
                <v-chip small>
                  {{ referringHistory.attributes.referral.data.attributes.name }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <div
          v-else
          class="ml-2"
        >
          ...
        </div>
      </v-card-text>
    </v-expand-transition>

    <v-expand-transition>
      <v-card-text v-show="opener">
        <!-- eslint-disable vue/v-on-event-hyphenation -->
        <referral-card
          v-for="(referringHistoryTemp, referringHistoryTempIdx) in referringHistoriesTemp"
          :key="`referringHistoriesTemp[${referringHistoryTempIdx}]`"
          :value="referringHistoryTemp"
          :referrals="referrals"
          :users="users"
          :referring-history-temp-idx="referringHistoryTempIdx"
          class="mb-4"
          @update:referralId="referringHistoryTemp.referralId = $event"
          @update:userId="referringHistoryTemp.userId = $event"
          @update:_destroy="referringHistoryTemp._destroy = $event"
          @remove="removeReferringHistoryTemp($event)"
        />

        <v-card
          elevation="0"
          class="d-flex align-center justify-center mb-2"
        >
          <v-btn
            fab
            icon
            large
            color="primary"
            :ripple="false"
            @click="addReferringHistoryTemp({})"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-card>
        <div
          v-if="isChanging"
          class="mt-3 px-5"
        >
          <div class="w-full d-flex justify-space-around align-center px-2">
            <v-btn
              color="primary"
              :loading="isSubmitting"
              :disabled="!isValidToSubmit || isSubmitting"
              :ripple="false"
              @click="updateReferringHistories"
            >
              更新
            </v-btn>
            <v-btn
              color="secondary"
              text
              small
              :ripple="false"
              class="ml-4"
              @click="opener = false"
            >
              キャンセル
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  inject,
  ref,
  watch,
  getCurrentInstance,
  onUnmounted,
} from '@vue/composition-api'
import { each, find, cloneDeep } from 'lodash'
import { mdiCogOutline, mdiAccountCircleOutline, mdiPlus } from '@mdi/js'
import useReferral from '@/views/composable/useReferral'
import useCurrentData from '@/views/composable/useCurrentData'
import ReferringHistoryApi from '@/api/waiter/ReferringHistory'
import ReferralCard from '@/views/components/table/ReferralCard.vue'

export default {
  components: {
    ReferralCard,
  },
  props: {
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const referringHistories = inject('referringHistories', [])
    const referrals = inject('referrals', [])
    const users = inject('users', [])

    const opener = ref(false)
    const isChanging = ref(false)
    const isSubmitting = ref(false)

    const {
      referringHistoriesTemp,
      addReferringHistoryTemp,
      removeReferringHistoryTemp,
      resetReferringHistoriesTemp,
      isValidToSubmit,
    } = useReferral()
    const { currentClub } = useCurrentData()

    const resetComp = () => {
      opener.value = false

      isChanging.value = false
      isSubmitting.value = false

      resetReferringHistoriesTemp()
    }

    const updateReferringHistories = async () => {
      isSubmitting.value = true

      const res = await ReferringHistoryApi.updateReferringHistories({
        tableId: props.tableId,
        referringHistories: referringHistoriesTemp.value,
      })

      if (res?.data) {
        emit('updated:referring-histories', res.data.referringHistories.data)
        emit('updated:table', res.data.table.data)

        vm.$toast.success(`${currentClub.value.referralAlias}を更新しました`)
      }

      resetComp()
    }

    // NOTE: リファラルパネルを開いたらreferringHistoriesTempをセットする/逆はreset
    watch(() => opener.value, (newBool, _prev) => {
      if (newBool) {
        each(referringHistories.value, referringHistory => {
          addReferringHistoryTemp({
            id: referringHistory.id,
            userId: referringHistory.attributes.user.data.id,
            referralId: referringHistory.attributes.referral.data.id,
          })
        })
      } else {
        isChanging.value = false
        resetComp()
      }
    })

    watch(() => cloneDeep(referringHistoriesTemp.value), (newVal, _prev) => {
      // NOTE: _destroyがtrue含まれていたら編集していると判断できる（削除指示をしているので）
      // eslint-disable-next-line no-underscore-dangle
      const isDestroying = !!find(newVal, referringHistoryTemp => referringHistoryTemp._destroy)

      // NOTE: idがないものがあったら編集していると判断できる（追加しているので）
      const isAdding = !!find(newVal, referringHistoryTemp => !referringHistoryTemp.id)

      isChanging.value = (isDestroying || isAdding)
    })

    onUnmounted(() => resetComp())

    return {
      referringHistories,
      referrals,
      users,

      opener,
      isChanging,
      isSubmitting,

      referringHistoriesTemp,
      removeReferringHistoryTemp,
      addReferringHistoryTemp,
      isValidToSubmit,
      updateReferringHistories,

      icons: {
        mdiCogOutline,
        mdiAccountCircleOutline,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
