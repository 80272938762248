<template>
  <div class="d-flex flex-column align-center">
    <div>
      <v-badge
        :content="customerCountTemp"
        inline
      >
        <v-icon
          large
          right
        >
          {{ icons.mdiAccountOutline }}
        </v-icon>
      </v-badge>

      <div class="d-flex flex-column">
        <div>
          <v-btn
            icon
            :ripple="false"
            @click="changeCustomerCount(-1)"
          >
            <v-icon>
              {{ icons.mdiMinus }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            :ripple="false"
            @click="changeCustomerCount(1)"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </div>

        <v-slide-y-transition>
          <div v-show="isChanging">
            <v-btn
              icon
              :ripple="false"
              color="primary"
              @click="updateCustomerCount"
            >
              <v-icon>
                {{ icons.mdiCheckCircleOutline }}
              </v-icon>
            </v-btn>

            <v-btn
              icon
              :ripple="false"
              color="error"
              @click="cancelChanging"
            >
              <v-icon>
                {{ icons.mdiCloseCircleOutline }}
              </v-icon>
            </v-btn>
          </div>
        </v-slide-y-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onUnmounted } from '@vue/composition-api'
import { clone } from 'lodash'
import {
  mdiAccountOutline,
  mdiPlus,
  mdiMinus,
  mdiCloseCircleOutline,
  mdiCheckCircleOutline,
} from '@mdi/js'
import TableApi from '@/api/waiter/Table'

export default {
  props: {
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    customerCount: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const customerCountTemp = ref(clone(props.customerCount))
    const isChanging = ref(false)

    const changeCustomerCount = number => {
      isChanging.value = true

      if ((customerCountTemp.value + number) <= 0) return

      customerCountTemp.value += number
    }

    const cancelChanging = () => {
      isChanging.value = false
      customerCountTemp.value = props.customerCount
    }

    const updateCustomerCount = async () => {
      isChanging.value = false

      const res = await TableApi.updateTable({
        tableId: props.tableId,
        params: { customerCount: customerCountTemp.value },
      })

      if (res?.data?.data) {
        emit('updated', res.data.data)

        vm.$toast.success('客数を更新しました')
      }
    }

    onUnmounted(() => isChanging.value = false)

    return {
      customerCountTemp,
      isChanging,

      changeCustomerCount,
      cancelChanging,
      updateCustomerCount,

      icons: {
        mdiAccountOutline,
        mdiPlus,
        mdiMinus,
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
      },
    }
  },
}
</script>
