import { ref, watch } from '@vue/composition-api'

export default () => {
  const target = ref(null)

  // NOTE
  // html.is-lockedでheightを変更するのでユーザーのスクロールポジションがロストする
  // そのためisActive時に現在地点を保存。非アクティブで元に戻す、ということをする。
  const scrollY = ref(0)

  const preventDefault = e => {
    e.preventDefault()
  }

  const preventBackgroundScrolling = isActive => {
    if (isActive) {
      scrollY.value = window.scrollY

      document.documentElement.classList.add('is-locked')
      document.documentElement.addEventListener('pointermove', preventDefault)
      document.documentElement.addEventListener('touchmove', preventDefault)
    } else {
      document.documentElement.classList.remove('is-locked')
      document.documentElement.removeEventListener('pointermove', preventDefault)
      document.documentElement.removeEventListener('touchmove', preventDefault)

      window.scrollTo(0, scrollY.value)
    }
  }

  watch(() => target.value?.isActive, (bool, _) => {
    preventBackgroundScrolling(bool)
  }, { immediate: true })

  return {
    target,
    scrollY,
    preventDefault,
    preventBackgroundScrolling,
  }
}
