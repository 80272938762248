<template>
  <div
    v-if="isVisible"
    :style="{ paddingRight: $vuetify.breakpoint.xsOnly ? 0 : '400px' }"
  >
    <v-lazy>
      <Menu
        :vendibles="vendibles"
        :vendible-tags="vendibleTags"
        :style="{ paddingTop: `${headerHeight}px`}"
        course-collapsed
        nomination-collapsed
        class="px-4"
        @click:row="addVendingHistoryTemp({
          vendibleId: $event.id,
          vendibleType: $event.attributes.vendibleType,
          sellingPrice: $event.attributes.sellingPrice,
        })"
      />
    </v-lazy>

    <v-navigation-drawer
      app
      right
      stateless
      permanent
      hide-overlay
      temporary
      :width="drawerWidth"
    >
      <v-card elevation="0">
        <v-card-text
          class="pb-16 mb-8"
          :style="{ paddingTop: `${headerHeight + 16}px`}"
        >
          <v-card elevation="0">
            <transition-group
              name="fadeUp"
              class="d-flex"
              :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-column-reverse'"
            >
              <div
                v-for="(vendingHistoryTemp, vendingHistoryTempIdx) in vendingHistoriesTemp"
                :key="`vendingHistoriesTemp[${vendingHistoryTempIdx}]`"
                style="animation-duration: 0.3s"
              >
                <!-- eslint-disable vue/v-on-event-hyphenation -->
                <order-card
                  :key="`order-card-${vendingHistoryTempIdx}/${vendingHistoriesTemp.length}`"
                  v-model="vendingHistoryTemp.value"
                  :vendibles="vendibles"
                  :users="users"
                  :size="vendingHistoryTemp.size"
                  class="mt-3 mb-8"
                  @update:vendibleId="vendingHistoryTemp.value.vendibleId = $event"
                  @update:vendibleType="vendingHistoryTemp.value.vendibleType = $event"
                  @update:isNonExistVendible="vendingHistoryTemp.value.isNonExistVendible = $event"
                  @update:vendibleName="vendingHistoryTemp.value.vendibleName = $event"
                  @update:sellingPrice="vendingHistoryTemp.value.sellingPrice = $event"
                  @update:taxCharge="vendingHistoryTemp.value.taxCharge = $event"
                  @update:vendingContributionsAttributes="vendingHistoryTemp.value.vendingContributionsAttributes = $event"
                  @update:isArbitraryVendingContribution="vendingHistoryTemp.isArbitraryVendingContribution = $event"
                  @update:vendibleRecipientsAttributes="vendingHistoryTemp.value.vendibleRecipientsAttributes = $event"
                  @update:size="vendingHistoryTemp.size = $event"
                  @remove="removeVendingHistoryTempByIndex(vendingHistoryTempIdx)"
                >
                  <template #outside>
                    <template v-if="isSplitEstimation">
                      <v-text-field
                        :value="`¥${tableTotal.toLocaleString()}`"
                        readonly
                        hide-details
                        disabled
                        class="right-input font-weight-bold px-2 pb-4"
                      >
                        <template #prepend-inner>
                          <span class="text-sm">
                            現在のお会計
                          </span>
                        </template>
                      </v-text-field>

                      <total-display
                        :subtotal="vendingHistoryTemp.value.sellingPrice * vendingHistoryTemp.size"
                        :tax="vendingHistoryTemp.estimatedTaxPrice * vendingHistoryTemp.size"
                        :total="getTotal(vendingHistoryTempIdx)"
                        :paid-in-advance-amount="paidInAdvanceAmount"
                        :title="'概算'"
                        :total-msg="'注文後のお会計'"
                        class="mb-8 mt-4 px-2"
                      >
                        <template #below-title>
                          <v-switch
                            v-if="vendingHistoriesTemp.length > 1"
                            v-model="isSplitEstimation"
                            messages="商品別"
                            dense
                          />
                        </template>
                      </total-display>
                    </template>
                  </template>
                </order-card>
              </div>
            </transition-group>
          </v-card>

          <v-card
            elevation="0"
            class="my-10 d-flex align-center justify-center"
          >
            <v-btn
              fab
              icon
              large
              color="primary"
              :ripple="false"
              @click="addVendingHistoryTemp({})"
            >
              <v-icon large>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </v-card>

          <template v-if="!isSplitEstimation">
            <v-text-field
              :value="`¥${tableTotal.toLocaleString()}`"
              readonly
              hide-details
              disabled
              class="right-input font-weight-bold px-2 pb-4"
            >
              <template #prepend-inner>
                <span class="text-sm">
                  現在のお会計
                </span>
              </template>
            </v-text-field>

            <total-display
              :subtotal="subtotal"
              :tax="totalTax"
              :total="estimatedTotal"
              :paid-in-advance-amount="paidInAdvanceAmount"
              :title="'概算'"
              :total-msg="'注文後のお会計'"
              class="mb-4 px-2"
            >
              <template #below-title>
                <v-switch
                  v-if="vendingHistoriesTemp.length > 1"
                  v-model="isSplitEstimation"
                  messages="商品別"
                  dense
                />
              </template>
            </total-display>
          </template>

          <div
            v-if="subtotal > 0"
            class="mt-10"
          >
            <print-btn
              subject="概算伝票"
              :print="printEstimatedSlip"
            >
              <template #append>
                <Preview
                  :slip-svg="slipSvg"
                  class="mt-1"
                />
              </template>
            </print-btn>
          </div>

          <v-app-bar
            bottom
            outlined
            fixed
            height="76"
            class="pa-0 order-app-bar"
          >
            <div class="d-flex flex-column align-center w-full h-full px-5">
              <v-spacer />
              <v-btn
                color="primary"
                block
                :ripple="false"
                :loading="isCreating"
                :disabled="!isValidContents || isCreating"
                @click="createVendingHistories"
              >
                {{ `注文（¥${(subtotal + totalTax).toLocaleString()}）` }}
              </v-btn>
              <v-spacer />
            </div>
          </v-app-bar>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  inject,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  cloneDeep,
  sumBy,
  flatMap,
  each,
  isEqual,
  map,
  isEmpty,
  includes,
  find,
  update,
} from 'lodash'
import {
  mdiPlus,
  mdiCalculatorVariant,
  mdiCalculatorVariantOutline,
  mdiArrowSplitHorizontal,
} from '@mdi/js'
import { useEstimatedSlipPrint } from '@yonarezi/yonarezi3.slip-print'
import { removePortFromIpAddress } from '@/utils/removePortFromIpAddress'
import TableApi from '@/api/waiter/Table'
import VendingHistoryApi from '@/api/waiter/VendingHistory'
import useVendingHistory from '@/views/composable/useVendingHistory'
import useDateFormat from '@/views/composable/useDateFormat'
import useCurrentData from '@/views/composable/useCurrentData'
import OrderCard from '@/views/components/table/OrderCard/OrderCard.vue'
import Menu from '@/views/components/vendible/Menu.vue'
import TotalDisplay from '@/views/components/util/TotalDisplay.vue'
import PrintBtn from '@/views/components/util/PrintBtn.vue'
import Preview from '@/views/components/printer/Preview.vue'

export default {
  components: {
    OrderCard,
    Menu,
    TotalDisplay,
    PrintBtn,
    Preview,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    headerHeight: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      default: 46,
    },
    drawerWidth: {
      type: [Number, String],
      default: 400,
    },
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tableNumber: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    total: {
      type: [Number, String],
      required: true,
    },
    paidInAdvanceAmount: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    customerCount: {
      type: [Number, String],
      required: true,
    },
    startAt: {
      type: String,
      required: true,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
    preDefinedVendingHistoriesTemp: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const isCreating = ref(false)
    const tableTotal = ref(props.total)
    const estimatedTotal = ref(0)
    const estimatedTotals = ref([])
    const isSplitEstimation = ref(false)

    const users = inject('users', [])
    const vendibles = inject('vendibles', [])
    const vendibleTags = inject('vendibleTags', [])
    const printer = inject('printer', null)

    const { currentClub } = useCurrentData()
    const { hhmm } = useDateFormat()

    const {
      vendingHistoriesTemp,
      addVendingHistoryTemp,
      removeVendingHistoryTempByIndex,
      resetVendingHistoriesTemp,
      isValidToSubmit,
      vendingHistoriesToSubmit,
      setVendingContributionsAttributes,
    } = useVendingHistory()

    vendingHistoriesTemp.value = cloneDeep(props.preDefinedVendingHistoriesTemp)

    const isValidContents = computed(() => {
      return vendingHistoriesToSubmit.value.length > 0 && isValidToSubmit.value
    })

    // NOTE: TAX計算をさせるwatch対象
    const taxEstimationTargets = computed(() => {
      return flatMap(vendingHistoriesTemp.value, o => {
        const { sellingPrice, taxCharge } = o.value

        return [sellingPrice, taxCharge]
      })
    })

    // NOTE: 大計計算をさせるwatch対象
    const totalEstimationTargets = computed(() => {
      // NOTE TAXの計算結果たち（注文内容の変化に応じてtax計算されるので）
      const estimatedTaxPrices = map(vendingHistoriesTemp.value, o => o.estimatedTaxPrice)

      // NOTE + 注文する内容の個数
      return [...estimatedTaxPrices, vendingHistoriesToSubmit.value.length]
    })

    const subtotal = computed(() => {
      return sumBy(vendingHistoriesToSubmit.value, vendingHistoryTemp => Number(vendingHistoryTemp.sellingPrice))
    })

    const totalTax = computed(() => {
      return sumBy(vendingHistoriesTemp.value, vendingHistoryTemp => {
        return vendingHistoryTemp.size * vendingHistoryTemp.estimatedTaxPrice
      })
    })

    const calcTotal = async (orderPrice = null) => {
      const res = await TableApi.calcTotal({
        tableId: props.tableId,
        orderPrice,
      })

      return res?.data
    }

    const calcTax = async price => {
      const res = await TableApi.calcTax({
        tableId: props.tableId,
        price,
      })

      return res?.data
    }

    const createVendingHistories = async () => {
      isCreating.value = true

      if (isValidContents.value) {
        const res = await VendingHistoryApi.createVendingHistories({
          tableId: props.tableId,
          vendingHistories: vendingHistoriesToSubmit.value,
        })

        if (res?.data) {
          emit('created:vending-histories', res.data.vendingHistories.data)
          emit('updated:table', res.data.table.data)

          tableTotal.value = await calcTotal()

          vm.$toast.success('注文しました')
        }
      }

      resetVendingHistoriesTemp()
      isCreating.value = false

      emit('ordered')
    }

    const getTotal = index => {
      const estimatedTotalObj = find(estimatedTotals.value, o => o.vendingHistoryTempIdx === index)

      return estimatedTotalObj?.amount
    }

    // NOTE: YONAREZI 3
    const estimatedSlipOptions = computed(() => {
      let targets = []
      if (isSplitEstimation.value) {
        targets = map(estimatedTotals.value, estimatedTotalObj => {
          const vendingHistoryTemp = vendingHistoriesTemp.value[estimatedTotalObj.vendingHistoryTempIdx]

          return {
            estimatedTotal: estimatedTotalObj.amount,
            estimatedTax: vendingHistoryTemp.estimatedTaxPrice * vendingHistoryTemp.size,
            vendingHistoriesTemp: [vendingHistoryTemp],
          }
        })
      } else {
        targets = [{
          estimatedTotal: estimatedTotal.value,
          estimatedTax: totalTax.value,
          vendingHistoriesTemp: vendingHistoriesTemp.value,
        }]
      }

      return {
        ipAddress: removePortFromIpAddress(currentClub.value.printIpAddress),
        startAt: hhmm(props.startAt),
        expireAt: props.expireAt ? hhmm(props.expireAt) : '',
        tableCode: [props.tablePrefix, props.tableNumber, props.tableSuffix].join(''),
        customerCount: props.customerCount,
        total: tableTotal.value,
        paidInAdvanceAmount: props.paidInAdvanceAmount,
        vendibles: vendibles.value,
        recipients: users.value,
        estimationTargets: targets,
        nominationAlias: currentClub.value.nominationAlias,
        courseAlias: currentClub.value.courseAlias,
        printer: printer.value,
      }
    })
    const { send: printEstimatedSlip, onReceive, svg: slipSvg } = useEstimatedSlipPrint(estimatedSlipOptions)
    onReceive.value = response => {
      if (!response.success) {
        vm.$toast.error(response)
      }
    }

    // NOTE: 概算を出すためにそれぞれのvendingHistoryの税サを計算
    watch(() => cloneDeep(taxEstimationTargets.value), (newVal, prevVal) => {
      if (isEqual(newVal, prevVal)) return

      each(vendingHistoriesTemp.value, vendingHistoryTemp => {
        if (vendingHistoryTemp.value.taxCharge === 'normal') {
          calcTax(vendingHistoryTemp.value.sellingPrice).then(taxPrice => {
            update(vendingHistoryTemp, 'estimatedTaxPrice', () => taxPrice)
          })
        } else {
          update(vendingHistoryTemp, 'estimatedTaxPrice', () => 0)
        }
      })
    }, { immediate: true })

    // NOTE: 概算を出すためにそれぞれのvendingHistoryの大計を計算
    watch(() => cloneDeep(totalEstimationTargets.value), (newVal, prevVal) => {
      if (isEmpty(newVal)) return
      if (includes(newVal, null)) return
      if (isEqual(newVal, [0])) return
      if (isEqual(newVal, prevVal)) return

      estimatedTotals.value = []
      each(vendingHistoriesTemp.value, (vendingHistoryTemp, index) => {
        const sellingPrice = Number(vendingHistoryTemp.value.sellingPrice)
        const estimatedTaxPrice = Number(vendingHistoryTemp.estimatedTaxPrice)
        const rawOrderTotal = (sellingPrice + estimatedTaxPrice) * vendingHistoryTemp.size

        calcTotal(rawOrderTotal).then(res => {
          estimatedTotals.value.push({
            vendingHistoryTempIdx: index,
            amount: res,
          })
        })
      })
    }, { immediate: true })

    // NOTE: 概算を出すためにvendingHistory全体の大計を計算
    watch(() => cloneDeep(estimatedTotals.value), (newVal, prevVal) => {
      if (isEqual(newVal, prevVal)) return

      const rawOrderTotal = subtotal.value + totalTax.value

      calcTotal(rawOrderTotal).then(res => {
        estimatedTotal.value = res
      })
    }, { immediate: true })

    // NOTE: 販売貢献人をvendingHistoryTempに自動セットするwatch
    watch(() => cloneDeep(vendingHistoriesTemp.value), (_newVal, _prevVal) => {
      setVendingContributionsAttributes({
        targetVendingHistoriesTemp: vendingHistoriesTemp.value,
      })
    })

    return {
      // data
      tableTotal,
      users,
      vendibles,
      vendibleTags,
      isCreating,
      vendingHistoriesTemp,
      estimatedTotal,
      estimatedTotals,
      isSplitEstimation,

      // computed
      isValidContents,
      subtotal,
      totalTax,
      taxEstimationTargets,
      totalEstimationTargets,

      // methods
      addVendingHistoryTemp,
      removeVendingHistoryTempByIndex,
      createVendingHistories,
      printEstimatedSlip,
      getTotal,
      slipSvg,
      icons: {
        mdiPlus,
        mdiCalculatorVariant,
        mdiCalculatorVariantOutline,
        mdiArrowSplitHorizontal,
      },
    }
  },
}
</script>
