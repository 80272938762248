<template>
  <v-card-text
    class="d-flex justify-space-between ma-0 pa-0"
  >
    <div
      v-if="startAt"
      class="d-flex align-center"
    >
      <v-icon
        :color="(!!expireAt && !isRunning) ? 'error' : 'primary'"
      >
        {{ icons.mdiPower }}
      </v-icon>

      <time
        :class="(!!expireAt && !isRunning) ? 'error--text' : ''"
      >
        {{ hhmm(startAt) }}
      </time>
    </div>
    <div
      v-if="displayNullStartAt && !startAt"
      class="d-flex align-center"
    >
      <v-icon>
        {{ icons.mdiPower }}
      </v-icon>

      <time>--:--</time>
    </div>

    <div v-if="expireAt">
      <countdown
        :key="`${id}${expireAt}`"
        ref="countdown"
        :end-time="expireAt"
        @start="isRunning = true"
        @finish="isRunning = false"
      >
        <template #process="{ timeObj }">
          <div class="d-flex align-center">
            <v-icon
              color="primary"
            >
              {{ icons.mdiTimerSand }}
            </v-icon>
            {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
          </div>
        </template>

        <template #finish>
          <div class="error--text d-flex align-center">
            <v-icon
              color="error"
            >
              {{ icons.mdiTimerSand }}
            </v-icon>
            {{ overTimeObj.h }}:{{ overTimeObj.m }}:{{ overTimeObj.s }}
          </div>
        </template>
      </countdown>
    </div>
    <div
      v-if="displayNullExpireAt && !expireAt"
      class="d-flex align-center"
    >
      <v-icon>
        {{ icons.mdiTimerSand }}
      </v-icon>

      <time>--:--:--</time>
    </div>

    <div
      v-if="expireAt"
      class="d-flex align-center"
    >
      <v-icon
        :color="isRunning ? 'primary' : 'error'"
      >
        {{ icons.mdiBellOutline }}
      </v-icon>

      <time
        :class="isRunning ? '' : 'error--text'"
      >
        {{ hhmm(expireAt) }}
      </time>
    </div>
    <div
      v-if="displayNullExpireAt && !expireAt"
      class="d-flex align-center"
    >
      <v-icon>
        {{ icons.mdiBellOutline }}
      </v-icon>

      <time>--:--</time>
    </div>
  </v-card-text>
</template>

<script>
import {
  mdiPower,
  mdiBellOutline,
  mdiTimerSand,
  mdiTimerSandEmpty,
} from '@mdi/js'
import {
  ref,
  reactive,
  watch,
  onUnmounted,
} from '@vue/composition-api'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import Vue from 'vue'
import { intervalToDuration } from 'date-fns'
import useDateFormat from '@/views/composable/useDateFormat'

Vue.use(vueAwesomeCountdown)

export default {
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    startAt: {
      type: String,
      required: false,
      default: null,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
    displayNullStartAt: {
      type: Boolean,
      default: false,
    },
    displayNullExpireAt: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { hhmm } = useDateFormat()

    const isRunning = ref(false)
    const countdown = ref(null)
    const overTimeObj = reactive({
      h: '00',
      m: '00',
      s: '00',
    })

    const resetOverTimeObj = () => {
      overTimeObj.h = '00'
      overTimeObj.m = '00'
      overTimeObj.s = '00'
    }

    const setOverTime = () => {
      if (!props.expireAt || isRunning.value) return

      const overTime = intervalToDuration({
        start: 0,
        end: new Date().getTime() - Date.parse(props.expireAt),
      })

      overTimeObj.h = (overTime.hours).toString().padStart(2, '0')
      overTimeObj.m = (overTime.minutes).toString().padStart(2, '0')
      overTimeObj.s = (overTime.seconds).toString().padStart(2, '0')
    }

    const overTimeInterval = setInterval(() => setOverTime(), 1000)

    onUnmounted(() => {
      clearInterval(overTimeInterval)
      resetOverTimeObj()
    })

    watch(() => isRunning.value, (newVal, _prevVal) => {
      if (newVal) resetOverTimeObj()
    })

    return {
      countdown,
      overTimeObj,
      isRunning,
      hhmm,
      icons: {
        mdiPower,
        mdiBellOutline,
        mdiTimerSand,
        mdiTimerSandEmpty,
      },
    }
  },
}
</script>
