<template>
  <p class="ma-0 pa-0">
    {{ [tablePrefix, tableNumber, tableSuffix].join('') }}
  </p>
</template>

<script>
export default {
  props: {
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    tableNumber: {
      type: [String, Number],
      required: true,
    },
  },
}
</script>
