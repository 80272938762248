<template>
  <v-card elevation="0">
    <v-card-text class="px-0 pt-0">
      <!-- 入店時間 start -->
      <table-start-at
        :table-id="tableId"
        :start-at="startAt"
        :expire-at="expireAt"
        class="px-5 my-5"
        @updated:table="$emit('updated:table', $event)"
        @updated:vending-histories="$emit('updated:vending-histories', $event)"
      />
      <!-- 入店時間 end -->

      <v-card elevation="0">
        <v-card-text class="py-0 px-4 d-flex align-center justify-space-between">
          <!-- 客数 start -->
          <customer-count
            :table-id="tableId"
            :customer-count="customerCount"
            @updated="$emit('updated:table', $event)"
          />
          <!-- 客数 end -->

          <!-- 現在のお会計 start -->
          <div class="d-flex flex-column align-center">
            <div class="d-flex pr-2">
              <sup>
                <v-icon>
                  {{ icons.mdiCurrencyJpy }}
                </v-icon>
              </sup>
              <span
                class="text-2xl font-weight-semibold"
              >
                {{ total.toLocaleString() }}
              </span>
            </div>

            <small class="text--secondary text-xs mt-2">
              (小計：¥{{ subtotal.toLocaleString() }})
            </small>
          </div>
          <!-- 現在のお会計 end -->
        </v-card-text>

        <customer-table-tagging
          :table-id="tableId"
          :customer-table-taggings="customerTableTaggings"
          class="px-4"
          @updated:table="$emit('updated:table', $event)"
        />
      </v-card>

      <v-divider class="mt-2 mb-1 mx-4" />

      <!-- リファラル start -->
      <referring-histories
        :table-id="tableId"
        @updated:referring-histories="$emit('updated:referring-histories', $event)"
        @updated:table="$emit('updated:table', $event)"
      />
      <!-- リファラル end -->

      <v-divider class="mt-2 mb-1 mx-4" />

      <div v-if="courseOverview && courseOverview.attributes">
        <!-- コース start -->
        <course-histories
          :table-id="tableId"
          :expire-at="expireAt"
          :course-overview="courseOverview"
          @updated:vending-histories="$emit('updated:vending-histories', $event)"
          @updated:table="$emit('updated:table', $event)"
        />
        <!-- コース end-->

        <v-divider class="mt-2 mb-1 mx-4" />
      </div>

      <div v-if="currentNominations && currentNominations.length > 0">
        <!-- ノミネーション start -->
        <nomination-histories
          :table-id="tableId"
          :current-nominations="currentNominations"
          @updated:vending-histories="$emit('updated:vending-histories', $event)"
          @updated:table="$emit('updated:table', $event)"
        />
        <!-- ノミネーション end -->

        <v-divider class="mt-2 mb-1 mx-4" />
      </div>

      <!-- 延長 start -->
      <extension-candidate-courses
        :key="ExtensionCandidateCoursesCompKey"
        :table-id="tableId"
        :table-number="tableNumber"
        :table-prefix="tablePrefix"
        :table-suffix="tableSuffix"
        :start-at="startAt"
        :expire-at="expireAt"
        :customer-count="customerCount"
        :total="total"
        :paid-in-advance-amount="paidInAdvanceAmount"
        @updated:vending-histories="$emit('updated:vending-histories', $event)"
        @updated:table="$emit('updated:table', $event)"
        @reload="ExtensionCandidateCoursesCompKey = `${Math.random()}`"
        @order="$emit('order')"
      />
      <!-- 延長 end -->
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCurrencyJpy } from '@mdi/js'
import CustomerTableTagging from '@/views/components/table/CustomerTableTagging.vue'
import TableStartAt from './TableStartAt.vue'
import CustomerCount from './CustomerCount.vue'
import ReferringHistories from './ReferringHistories.vue'
import CourseHistories from './CourseHistories.vue'
import NominationHistories from './NominationHistories.vue'
import ExtensionCandidateCourses from './ExtensionCandidateCourses.vue'

export default {
  components: {
    TableStartAt,
    CustomerCount,
    ReferringHistories,
    CourseHistories,
    NominationHistories,
    ExtensionCandidateCourses,
    CustomerTableTagging,
  },
  props: {
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tableNumber: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    startAt: {
      type: String,
      required: true,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
    customerCount: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    subtotal: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    total: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    paidInAdvanceAmount: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    currentNominations: {
      // NOTE: 中身は今生きてるNominationのvending_history
      type: Array,
      default: () => [],
    },
    courseOverview: {
      // NOTE: 中身はその卓で一番終わるまでの時間が長いコースのvendingHistory
      type: Object,
      default: () => {},
    },
    customerTableTaggings: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const ExtensionCandidateCoursesCompKey = ref(`${Math.random()}`)

    // const update = async params => {
    //   TableApi.updateTable({ tableId: props.tableId, params })
    // }

    return {
      ExtensionCandidateCoursesCompKey,

      icons: {
        mdiCurrencyJpy,
      },
    }
  },
}
</script>
