<template>
  <div
    v-if="Object.keys(nomineesByNominationId || {}).length > 0"
    class="d-flex align-center flex-wrap"
  >
    <div
      v-for="(nominees, vendibleId) in nomineesByNominationId"
      :key="`nomination${vendibleId}`"
      class="mr-2"
    >
      <v-avatar
        v-for="nominee in nominees"
        :key="`nomination-${vendibleId}-display-for-user-${nominee.id}`"
        size="46"
        :color="pickNomination(vendibleId).attributes.color"
        class="mr-2 mt-4"
      >
        <v-img
          v-if="nominee.attributes.profileImage"
          :src="nominee.attributes.profileImage"
          max-height="42"
          max-width="42"
        />
        <span
          v-else
          class="text-xs font-weight-bold"
        >
          {{ nominee.attributes.name.substring(0, 3) }}
        </span>
      </v-avatar>
    </div>
  </div>
</template>

<script>
import { computed, inject } from '@vue/composition-api'
import {
  groupBy,
  chain,
  mapValues,
  flatMap,
  first,
  find,
} from 'lodash'

export default {
  props: {
    nominationHistories: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const nominations = inject('nominations', [])

    const nomineesByNominationId = computed(() => {
      if (props.nominationHistories.length <= 0) return {}

      return chain(props.nominationHistories)
        .groupBy(o => o.attributes.vendibleId)
        .mapValues(vendingHistories => {
          return chain(vendingHistories)
            .flatMap(vendingHistory => {
              const vendibleRecipients = vendingHistory.attributes?.vendibleRecipients?.data

              // NOTE:
              // vendibleRecipientsは複数想定だが指名のときは一つだけしか基本的に持たない(入力のUIもそうなってる)
              // そのためfirstをつかっている
              return first(vendibleRecipients)?.attributes?.recipient?.data || []
            })
            .value()
        })
        .value()
    })

    const pickNomination = id => {
      return find(nominations.value, o => +o.id === +id) || { attributes: { color: null } }
    }

    return {
      // computed
      nomineesByNominationId,

      // methods
      pickNomination,
    }
  },
}
</script>
