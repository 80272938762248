<template>
  <v-card elevation="0">
    <v-card-text class="d-flex align-end ma-0 pa-0">
      <v-text-field
        v-model="tableStartAtTemp"
        prefix="入店時間："
        type="datetime-local"
        hide-details
        class="pa-0 ma-0"
        @mousedown="isChanging = true"
      >
        <template #prepend>
          <v-icon
            :color="isExpired ? 'error' : 'primary'"
            left
          >
            {{ icons.mdiPower }}
          </v-icon>
        </template>
        <template #append-outer>
          <v-slide-y-transition>
            <div
              v-if="isChanging"
              class="d-flex"
            >
              <v-btn
                icon
                color="primary"
                :ripple="false"
                @click="updateTableStartAt"
              >
                <v-icon>
                  {{ icons.mdiCheckCircleOutline }}
                </v-icon>
              </v-btn>

              <v-btn
                icon
                color="error"
                :ripple="false"
                @click="cancel"
              >
                <v-icon>
                  {{ icons.mdiCloseCircleOutline }}
                </v-icon>
              </v-btn>
            </div>
          </v-slide-y-transition>
        </template>
      </v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
  watch,
} from '@vue/composition-api'
import {
  mdiPower,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
} from '@mdi/js'
import { add } from 'date-fns'
import TableApi from '@/api/waiter/Table'
import useCurrentData from '@/views/composable/useCurrentData'
import useDateFormat from '@/views/composable/useDateFormat'

export default {
  props: {
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    startAt: {
      type: String,
      required: true,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const { currentClub } = useCurrentData()
    const { dateTimeForJsFriendly } = useDateFormat()

    const tableStartAtTemp = ref(dateTimeForJsFriendly(props.startAt))
    const isChanging = ref(false)

    const isExpired = computed(() => {
      return Date.parse(props.expireAt) - new Date() <= 0
    })

    const maxDate = computed(() => {
      return add(new Date(props.startAt), { days: 1 })
    })

    const cancel = () => {
      tableStartAtTemp.value = dateTimeForJsFriendly(props.startAt)
      isChanging.value = false
    }

    watch(() => isChanging.value, (newBool, _) => {
      if (!newBool) return

      if (isExpired.value) {
        vm.$toast.info(`現在アクティブな${currentClub.value.courseAlias}がありません。入店時間の更新は${currentClub.value.courseAlias}の時間に影響しません。`)
      } else {
        vm.$toast.warning(`入店時間の更新は現在アクティブな${currentClub.value.courseAlias}の時間も更新します。`)
      }
    })

    const updateTableStartAt = async () => {
      const res = await TableApi.updateTable({
        tableId: props.tableId,
        params: { startAt: tableStartAtTemp.value },
      })

      if (res?.data?.data) {
        const tableData = res.data.data
        emit('updated:table', tableData)
        emit('updated:vending-histories', tableData.attributes.vendingHistories.data)

        vm.$toast.success('入店時間を更新しました')
      }

      isChanging.value = false
    }

    const cancelChanging = () => {
      tableStartAtTemp.value = props.startAt
    }

    return {
      // data
      tableStartAtTemp,
      isChanging,

      // computed
      isExpired,
      maxDate,

      // methods
      updateTableStartAt,
      cancelChanging,
      cancel,

      icons: {
        mdiPower,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
      },
    }
  },
}
</script>
