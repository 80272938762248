<template>
  <v-card
    flat
    class="pa-2"
  >
    <v-text-field
      v-model="temp.startAt"
      prefix="開始："
      type="datetime-local"
      class="pa-0 ma-0"
      @input="update('startAt', $event)"
    />
    <v-text-field
      v-model="temp.releaseAt"
      prefix="終了："
      type="datetime-local"
      hide-details
      class="pa-0 ma-0"
      @input="update('releaseAt', $event)"
    />
  </v-card>
</template>

<script>
import {
  reactive,
  computed,
} from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import {
  mdiSelection,
  mdiHeartOutline,
  mdiAccountCircleOutline,
  mdiPower,
  mdiBellOutline,
} from '@mdi/js'
import useDateFormat from '@/views/composable/useDateFormat'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const startAt = useVModel(props.value, 'startAt')
    const releaseAt = useVModel(props.value, 'releaseAt')
    const { dateTimeForJsFriendly } = useDateFormat()

    const temp = reactive({
      startAt: props.value.startAt ? dateTimeForJsFriendly(props.value.startAt) : '',
      releaseAt: props.value.releaseAt ? dateTimeForJsFriendly(props.value.releaseAt) : '',
    })

    const updateTarget = computed(() => {
      return { startAt, releaseAt }
    })

    const update = (key, val) => {
      updateTarget.value[key].value = val
      temp[key] = val
    }

    return {
      startAt,
      releaseAt,
      temp,
      update,
      icons: {
        mdiSelection,
        mdiHeartOutline,
        mdiAccountCircleOutline,
        mdiPower,
        mdiBellOutline,
      },
    }
  },
}
</script>
