<template>
  <v-card
    v-if="nominations.length > 0"
    elevation="0"
  >
    <v-card-title class="pb-0">
      <p class="pa-0 ma-0 text--secondary text-sm">
        {{ currentClub.nominationAlias }}
      </p>
      <v-spacer />
      <v-btn
        icon
        fab
        :ripple="false"
        @click="opener = !opener"
      >
        <v-icon>
          {{ icons.mdiCogOutline }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <v-card-text v-show="!opener">
        <nomination-display
          v-if="currentNominations.length > 0"
          :nomination-histories="currentNominations"
        />

        <div
          v-else
          class="ml-2"
        >
          ...
        </div>
      </v-card-text>
    </v-expand-transition>

    <v-expand-transition>
      <v-card-text
        v-show="opener"
        class="mt-6"
      >
        <v-timeline
          dense
          class="timeline-custom-dense timeline-custom-dots"
        >
          <v-timeline-item
            v-for="(nominationHistory, nominationHistoryIdx) in nominationHistories"
            :key="`nominationHistories[${nominationHistoryIdx}]`"
            small
          >
            <template #icon>
              <v-avatar
                size="30"
                :color="nominationHistory.meta.color"
              >
                <v-img
                  v-if="nominationHistory.attributes.vendibleRecipients.data[0].attributes.recipient.data.attributes.profileImage"
                  :src="nominationHistory.attributes.vendibleRecipients.data[0].attributes.recipient.data.attributes.profileImage"
                />
                <v-icon v-else>
                  {{ icons.mdiAccountCircleOutline }}
                </v-icon>
              </v-avatar>
            </template>
            <div class="d-flex align-top pb-3">
              <p
                v-if="nominationHistory.attributes.persistenceReport
                  && nominationHistory.attributes.persistenceReport.data
                  && nominationHistory.attributes.persistenceReport.data.attributes
                  && nominationHistory.attributes.persistenceReport.data.attributes.startAt"
                class="text-sm pa-0 ma-0 mb-1"
              >
                {{ hhmm(nominationHistory.attributes.persistenceReport.data.attributes.startAt) }}
              </p>
              <v-spacer />
              <div class="d-flex flex-column align-end pr-2">
                <p class="text-xs pa-0 ma-0 secondary--text">
                  {{ nominationHistory.attributes.vendibleRecipients.data[0].attributes.recipient.data.attributes.name }}
                </p>
                <p class="pa-0 ma-0 text-sm">
                  {{ nominationHistory.attributes.vendibleName }}
                </p>
              </div>
            </div>

            <!-- ノミネーションの時間変更 -->
            <div v-if="isChanging === nominationHistoryIdx">
              <!-- eslint-disable vue/v-on-event-hyphenation -->
              <persistence-report-card
                v-for="(nominationHistoryTemp, nominationHistoryTempIdx) in nominationHistoriesTemp"
                :key="`nominationHistoriesTemp[${nominationHistoryTempIdx}]`"
                :value="nominationHistoryTemp.persistenceReportAttributes"
                class="my-5"
                @update:startAt="nominationHistoryTemp.persistenceReportAttributes.startAt = $event"
                @update:releaseAt="nominationHistoryTemp.persistenceReportAttributes.releaseAt = $event"
              />
              <div class="w-full d-flex justify-space-around align-center px-2">
                <v-btn
                  :ripple="false"
                  color="primary"
                  :loading="isSubmitting"
                  :disabled="!isValidPersistenceReportsToSubmit || isSubmitting"
                  @click="updateNominationHistories"
                >
                  更新
                </v-btn>
                <v-btn
                  :ripple="false"
                  color="secondary"
                  text
                  small
                  class="ml-4"
                  @click="cancelChanging"
                >
                  キャンセル
                </v-btn>
              </div>
            </div>
            <!-- ノミネーションの時間変更 -->

            <div
              v-else
              class="d-flex align-center"
            >
              <table-timer
                v-if="nominationHistory.attributes.persistenceReport
                  && nominationHistory.attributes.persistenceReport.data
                  && nominationHistory.attributes.persistenceReport.data.attributes"
                :id="nominationHistory.id"
                :expire-at="nominationHistory.attributes.persistenceReport.data.attributes.releaseAt"
                :display-null-expire-at="true"
                class="ma-0 pa-0 mr-1"
              />
              <v-btn
                small
                right
                :ripple="false"
                icon
                @click="changePersistenceReports(nominationHistoryIdx, nominationHistory)"
              >
                <v-icon small>
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  inject,
  ref,
  computed,
  watch,
  getCurrentInstance,
  onUnmounted,
} from '@vue/composition-api'
import {
  every,
  chain,
} from 'lodash'
import { mdiCogOutline, mdiAccountCircleOutline, mdiPencilOutline } from '@mdi/js'
import useCurrentData from '@/views/composable/useCurrentData'
import useDateFormat from '@/views/composable/useDateFormat'
import usePersistenceReport from '@/views/composable/usePersistenceReport'
import VendingHistoryApi from '@/api/waiter/VendingHistory'
import TableTimer from '@/views/components/table/TableTimer.vue'
import PersistenceReportCard from '@/views/components/table/PersistenceReportCard.vue'
import NominationDisplay from '@/views/components/table/NominationDisplay.vue'

export default {
  components: {
    TableTimer,
    PersistenceReportCard,
    NominationDisplay,
  },
  props: {
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    currentNominations: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const vendingHistories = inject('vendingHistories', [])
    const nominations = inject('nominations', [])

    const nominationHistoriesTemp = ref([])
    const opener = ref(false)
    const isChanging = ref(false)
    const isSubmitting = ref(false)

    const { currentClub } = useCurrentData()
    const { hhmm } = useDateFormat()
    const {
      isValid: isValidPersistenceReport,
      buildPersistenceReportTemp,
    } = usePersistenceReport()

    const isValidPersistenceReportsToSubmit = computed(() => {
      return every(nominationHistoriesTemp.value, nominationHistoryTemp => isValidPersistenceReport(nominationHistoryTemp.persistenceReportAttributes))
    })

    const nominationHistories = computed(() => {
      return chain(vendingHistories.value)
        .filter(vendingHistory => vendingHistory.attributes.vendibleType === 'Nomination')
        .orderBy(['attributes.persistenceReport.data.attributes.startAt', 'attributes.vendibleId'], ['desc', 'asc'])
        .value()
    })

    const cancelChanging = () => {
      isChanging.value = false
      nominationHistoriesTemp.value = []
    }

    const resetComp = () => {
      opener.value = false
      isSubmitting.value = false
      cancelChanging()
    }

    const updateNominationHistories = async () => {
      isSubmitting.value = true

      const res = await VendingHistoryApi.updateVendingHistories({
        tableId: props.tableId,
        vendingHistories: nominationHistoriesTemp.value,
      })

      if (res?.data) {
        emit('updated:vending-histories', res.data.vendingHistories.data)
        emit('updated:table', res.data.table.data)

        vm.$toast.success(`${currentClub.value.nominationAlias}の時間を更新しました`)
      }

      isSubmitting.value = false
      cancelChanging()
    }

    // NOTE: コースは複数いっきにやるのに対してノミネーションはひとつずつ（eachを使わない）
    const changePersistenceReports = (index, nominationHistory) => {
      nominationHistoriesTemp.value = []

      isChanging.value = index

      const nominationHistoryTemp = { id: nominationHistory.id }

      const persistenceReport = nominationHistory.attributes.persistenceReport.data

      const {
        vendingHistoryId,
        startAt,
        releaseAt,
      } = persistenceReport.attributes

      const persistenceReportTemp = buildPersistenceReportTemp({
        id: persistenceReport.id,
        vendingHistoryId,
        startAt,
        releaseAt,
      })

      nominationHistoryTemp.persistenceReportAttributes = persistenceReportTemp
      nominationHistoriesTemp.value.push(nominationHistoryTemp)
    }

    // NOTE: 閉めたらreset
    watch(() => opener.value, (newBool, _prev) => {
      if (!newBool) resetComp()
    })

    onUnmounted(() => resetComp())

    return {
      nominations,
      nominationHistoriesTemp,
      opener,
      isChanging,
      isSubmitting,

      isValidPersistenceReportsToSubmit,
      nominationHistories,

      updateNominationHistories,
      changePersistenceReports,
      cancelChanging,

      hhmm,

      icons: {
        mdiCogOutline,
        mdiAccountCircleOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
