<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-center"
      style="min-height: 36px"
    >
      <table-name
        :table-number="tableNumber"
        :table-prefix="tablePrefix"
        :table-suffix="tableSuffix"
        class="text-xs mb-1"
      />
    </div>

    <v-card
      class="flex-grow-1 elevation-4 d-flex align-center justify-center"
      min-height="236.5"
      color="transparent"
      :disabled="table && table.hold"
      :ripple="false"
      @click="$emit('clicked')"
    >
      <beat-loader
        v-if="table && table.hold"
        :loading="true"
        :color="'#8A8D93'"
        size="10px"
      />
      <v-icon
        v-else
        large
      >
        {{ icons.mdiPlus }}
      </v-icon>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import TableName from '@/views/components/table/TableName.vue'

export default {
  components: {
    TableName,
    BeatLoader,
  },
  props: {
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    tableNumber: {
      type: [String, Number],
      required: true,
    },
    table: {
      type: [Object, undefined],
      default: undefined,
    },
  },
  setup() {
    return {
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
