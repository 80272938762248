import useValidation from '@/views/composable/useValidation'

export default () => {
  const {
    isValidId,
    isValidDate,
  } = useValidation()

  const isValid = persistenceReport => {
    return isValidId(persistenceReport.vendingHistoryId)
      && isValidDate(persistenceReport.startAt)
      && isValidDate(persistenceReport.releaseAt)
  }

  const buildPersistenceReportTemp = ({
    id = null,
    vendingHistoryId = null,
    startAt = null,
    releaseAt = null,
  }) => {
    return {
      id,
      vendingHistoryId,
      startAt,
      releaseAt,
    }
  }

  return {
    isValid,
    buildPersistenceReportTemp,
  }
}
