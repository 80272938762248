<template>
  <div
    v-click-outside="{
      handler: close,
      closeConditional,
      include: include,
    }"
  >
    <v-navigation-drawer
      :key="`table-show-drawer-${tableNumber}`"
      ref="drawer"
      :value="isVisible"
      app
      right
      temporary
      stateless
      :width="currentTabIdx === 0 ? '400' : '100%'"
    >
      <!-- NOTE v-app-barのv-ifは設定しないとHall.vueのタブ移動でなぜか一瞬表示されてしまう問題対策 -->
      <v-app-bar
        v-if="isVisible"
        ref="header"
        outlined
        fixed
        flat
        style="z-index: 10"
      >
        <v-app-bar-nav-icon>
          <v-btn
            fab
            small
            :ripple="false"
            @click="close"
          >
            <v-icon>
              {{ icons.mdiDotsGrid }}
            </v-icon>
          </v-btn>
        </v-app-bar-nav-icon>

        <v-app-bar-title>
          <template #default>
            <table-name
              :table-number="tableNumber"
              :table-prefix="tablePrefix"
              :table-suffix="tableSuffix"
            />
          </template>
        </v-app-bar-title>

        <v-spacer></v-spacer>

        <v-btn
          icon
          fab
          :ripple="false"
          :color="currentTabIdx === 0 ? 'primary' : ''"
          class="vertical-btn-text mr-1"
          @click="currentTabIdx = 0"
        >
          <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
          <span class="text-xs">概要</span>
        </v-btn>

        <v-btn
          icon
          fab
          :ripple="false"
          :color="currentTabIdx === 1 ? 'primary' : ''"
          class="vertical-btn-text mx-1"
          @click="currentTabIdx = 1"
        >
          <v-icon>{{ icons.mdiCartVariant }}</v-icon>
          <span class="text-xs">注文</span>
        </v-btn>

        <v-btn
          icon
          fab
          :ripple="false"
          :color="currentTabIdx === 2 && checkCompMode === 'check' ? 'primary' : ''"
          class="vertical-btn-text ml-1"
          @click="clickCheckComp('check')"
        >
          <v-icon>{{ icons.mdiCurrencyJpy }}</v-icon>
          <span class="text-xs">伝票</span>
        </v-btn>

        <v-btn
          v-if="$vuetify.breakpoint.xsOnly"
          icon
          fab
          :ripple="false"
          :color="currentTabIdx === 2 && checkCompMode === 'history' ? 'primary' : ''"
          class="vertical-btn-text ml-1"
          @click="clickCheckComp('history')"
        >
          <v-icon>
            {{ icons.mdiHistory }}
          </v-icon>
          <span class="text-xs">明細</span>
        </v-btn>
      </v-app-bar>

      <v-card
        tile
        elevation="0"
        height="100%"
      >
        <v-tabs-items
          v-model="currentTabIdx"
          touchless
          class="h-full"
        >
          <!-- 概要 start -->
          <v-tab-item
            class="px-1 mb-8 h-full"
            :style="{ paddingTop: `${headerHeight}px` }"
          >
            <overview
              v-if="isVisible && currentTabIdx === 0"
              :table-id="tableId"
              :table-number="tableNumber"
              :table-prefix="tablePrefix"
              :table-suffix="tableSuffix"
              :start-at="startAt"
              :expire-at="expireAt"
              :customer-count="customerCount"
              :subtotal="subtotal"
              :total="total"
              :paid-in-advance-amount="paidInAdvanceAmount"
              :current-nominations="currentNominations"
              :course-overview="courseOverview"
              :customer-table-taggings="customerTableTaggings"
              @updated:vending-histories="updateVendingHistories($event)"
              @updated:referring-histories="updateReferringHistories($event)"
              @updated:table="$emit('updated', $event)"
              @order="currentTabIdx = 1"
            />
          </v-tab-item>
          <!-- 概要 end -->

          <!-- 注文 start -->
          <v-tab-item
            reverse-transition
            class="h-full"
          >
            <order
              v-if="isVisible && currentTabIdx === 1"
              :is-visible="isVisible && currentTabIdx === 1"
              :header-height="headerHeight"
              :drawer-width="$vuetify.breakpoint.xsOnly ? '100%' : 400"
              :table-id="tableId"
              :table-number="tableNumber"
              :table-prefix="tablePrefix"
              :table-suffix="tableSuffix"
              :total="total"
              :paid-in-advance-amount="paidInAdvanceAmount"
              :start-at="startAt"
              :expire-at="expireAt"
              :customer-count="customerCount"
              :pre-defined-vending-histories-temp="preDefinedVendingHistoriesTemp"
              @created:vending-histories="updateVendingHistories($event)"
              @updated:table="$emit('updated', $event)"
              @ordered="close"
            />
          </v-tab-item>
          <!-- 注文 end -->

          <!-- 会計系 start -->
          <v-tab-item
            reverse-transition
            class="h-full"
          >
            <!-- eslint-disable vue/v-on-event-hyphenation -->
            <check
              v-if="isVisible && currentTabIdx === 2"
              :is-visible="isVisible && currentTabIdx === 2"
              :header-height="headerHeight"
              :drawer-width="$vuetify.breakpoint.xsOnly ? '100%' : 400"
              :table-id="tableId"
              :start-at="startAt"
              :is-check-drawer-open="isCheckDrawerOpen"
              :customer-count="customerCount"
              :table-name="[tablePrefix, tableNumber, tableSuffix].join('')"
              @update:isCheckDrawerOpen="isCheckDrawerOpen = $event"
              @updated:vending-histories="updateVendingHistories($event)"
              @updated:table="$emit('updated', $event)"
              @checked="checked"
              @init:reset-func="checkCompFunc.reset = $event"
              @init:edit-func="editVendingHistoriesFunc = $event"
              @to-order="currentTabIdx = 1"
            />
          </v-tab-item>
          <!-- 会計系 end -->
        </v-tabs-items>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  provide,
  watch,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiDotsGrid,
  mdiInformationOutline,
  mdiCartVariant,
  mdiCurrencyJpy,
  mdiHistory,
} from '@mdi/js'
import { getVuetify, useRouter } from '@core/utils'
import { find } from 'lodash'
import TableApi from '@/api/waiter/Table'
import usePreventBackgroundScrolling from '@/views/composable/usePreventBackgroundScrolling'
import TableName from '@/views/components/table/TableName.vue'
import Overview from './Overview/Overview.vue'
import Order from './Order.vue'
import Check from './Check/Check.vue'

export default {
  components: {
    TableName,
    Overview,
    Order,
    Check,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tableNumber: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    startAt: {
      type: String,
      required: true,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
    customerCount: {
      type: [Number, String],
      required: true,
    },
    subtotal: {
      type: [Number, String],
      required: true,
    },
    total: {
      type: [Number, String],
      required: true,
    },
    paidInAdvanceAmount: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    currentNominations: {
      // NOTE: 中身はNominationだけのvending_history
      type: Array,
      default: () => [],
    },
    courseOverview: {
      // NOTE: 中身はその卓で一番終わるまでの時間が長いコースのvendingHistory
      type: Object,
      default: () => {},
    },
    customerTableTaggings: {
      type: Array,
      default: () => [],
    },
    defaultTabIdx: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      default: 0,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const $vuetify = getVuetify()

    const header = ref(null)
    const currentTabIdx = ref(props.defaultTabIdx)
    const isCheckDrawerOpen = ref(false)
    const checkCompMode = ref('')
    const checkCompFunc = reactive({
      reset: () => {},
    })
    const editVendingHistoriesFunc = ref(() => {})

    const vendingHistories = ref([])
    const referringHistories = ref([])
    const preDefinedVendingHistoriesTemp = ref([])
    provide('vendingHistories', vendingHistories)
    provide('referringHistories', referringHistories)
    provide('preDefinedVendingHistoriesTemp', preDefinedVendingHistoriesTemp)

    const { route, router } = useRouter()
    const { target: drawer } = usePreventBackgroundScrolling()

    const headerHeight = computed(() => {
      return header.value?.$el?.clientHeight || 46
    })

    const getTable = async () => {
      const res = await TableApi.getTable(props.tableId)

      if (res?.data?.data) {
        if (res.data.data.attributes.receipt?.data?.id) {
          vm.$toast.warning('すでに会計済みです')
          router.go(0)
        }

        const table = res.data.data
        referringHistories.value = table.attributes.referringHistories.data
        vendingHistories.value = table.attributes.vendingHistories.data
      }
    }

    const updateVendingHistories = newVendingHistories => {
      vendingHistories.value = newVendingHistories
    }

    const updateReferringHistories = newReferringHistories => {
      referringHistories.value = newReferringHistories
    }

    const close = () => {
      emit('close')
      preDefinedVendingHistoriesTemp.value = []
      checkCompFunc.reset()
      currentTabIdx.value = 0
      isCheckDrawerOpen.value = false
      checkCompMode.value = ''
    }

    const checked = receipt => {
      emit('checked', receipt)
      close()
    }

    const clickCheckComp = mode => {
      currentTabIdx.value = 2
      const func = {
        check: () => {
          checkCompMode.value = 'check'
          checkCompFunc.reset()
          isCheckDrawerOpen.value = true
        },
        history: () => {
          checkCompMode.value = 'history'
          checkCompFunc.reset()
          isCheckDrawerOpen.value = false
        },
      }

      func[mode]()
    }

    getTable().then(() => {
      if (route.value.query.vendingHistoryId || route.value.query.receipt) {
        checkCompMode.value = $vuetify.breakpoint.xsOnly ? 'history' : 'check'

        const targetVendingHistory = find(vendingHistories.value, o => Number(o.id) === Number(route.value.query.vendingHistoryId))
        if (targetVendingHistory?.id) {
          editVendingHistoriesFunc.value([targetVendingHistory])
        } else {
          isCheckDrawerOpen.value = true
        }
      }
    })

    watch(() => props.isVisible, (newBool, prevBool) => {
      if (!prevBool && newBool) {
        getTable()
      }
    })

    return {
      // data
      header,
      currentTabIdx,
      isCheckDrawerOpen,
      checkCompMode,
      checkCompFunc,
      preDefinedVendingHistoriesTemp,
      drawer,

      // computed
      headerHeight,

      // methods
      updateVendingHistories,
      updateReferringHistories,
      close,
      checked,
      editVendingHistoriesFunc,
      clickCheckComp,
      closeConditional: () => !!document.querySelector('.v-overlay--active') && props.isVisible && !document.querySelector('.receipt-preview.v-dialog--active'),
      include: () => [
        ...document.querySelectorAll('.v-navigation-drawer--open'),
        ...document.querySelectorAll('.v-menu__content'),
        ...document.querySelectorAll('.v-dialog__content'),
        ...document.querySelectorAll('.Vue-Toastification__toast'),
      ],

      icons: {
        mdiDotsGrid,
        mdiInformationOutline,
        mdiCartVariant,
        mdiCurrencyJpy,
        mdiHistory,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-window {
  height: 100%;
  overflow: auto;
}

.v-tab__items,
.v-window-item,
.v-window >>> div.v-window__container {
  height: 100%;
}
</style>
