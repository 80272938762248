<template>
  <v-card elevation="0">
    <v-card-title>
      <p class="pa-0 ma-0 text--secondary text-sm">
        延長
      </p>
      <v-spacer />
      <v-btn
        v-show="opener"
        fab
        icon
        :ripple="false"
        class="mr-2"
        @click.prevent="$emit('reload')"
      >
        <v-icon>
          {{ icons.mdiReload }}
        </v-icon>
      </v-btn>

      <v-btn
        icon
        fab
        :ripple="false"
        @click="opener = !opener"
      >
        <v-icon>
          {{ icons.mdiCogOutline }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <v-card-text v-show="opener">
        <div v-if="hasExtensionCandidateCourses">
          <div
            v-for="(extensionCandidate, extensionCandidateIdx) in extensionCandidates"
            :key="`extensionCandidates[${extensionCandidateIdx}]`"
            class="mb-5"
          >
            <v-chip
              class="mb-2 v-chip-light-bg accent--text font-weight-semibold"
            >
              <v-icon
                class="me-1"
                color="accent"
                size="18"
              >
                {{ icons.mdiLightbulbOutline }}
              </v-icon>
              {{ `候補 ${extensionCandidateIdx + 1}` }}
            </v-chip>
            <!-- eslint-disable vue/v-on-event-hyphenation -->
            <order-card
              v-model="extensionCandidate.courseHistoryTemp.value"
              :vendibles="courses"
              :size="extensionCandidate.courseHistoryTemp.size"
              :removable="false"
              :hide-vending-contributors="true"
              @update:vendibleId="extensionCandidate.courseHistoryTemp.value.vendibleId = $event"
              @update:sellingPrice="extensionCandidate.courseHistoryTemp.value.sellingPrice = $event"
              @update:taxCharge="extensionCandidate.courseHistoryTemp.value.taxCharge = $event"
              @update:size="extensionCandidate.courseHistoryTemp.size = $event"
            >
              <template #default>
                <v-expansion-panels
                  v-if="currentNominationsTempOfArray[extensionCandidateIdx].length > 0"
                  flat
                  class="mt-2"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      hide-actions
                      class="px-2 pt-1"
                    >
                      <div>
                        <div class="d-flex align-center">
                          <p class="pa-0 ma-0 text--disabled text-sm">
                            <v-icon>
                              {{ icons.mdiAttachment }}
                            </v-icon>
                            {{ currentClub.nominationAlias }}
                          </p>
                        </div>

                        <nomination-display
                          v-if="currentNominationsTempOfArray[extensionCandidateIdx].length > 0"
                          :nomination-histories="currentNominationsTempOfArray[extensionCandidateIdx]"
                        />
                        <div
                          v-else
                          class="ml-2"
                        >
                          ...
                        </div>
                      </div>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <!-- eslint-disable vue/v-on-event-hyphenation -->
                      <order-card
                        v-for="(nominationHistoryTemp, nominationHistoryTempIdx) in extensionCandidate.nominationHistoriesTemp"
                        :key="`extensionCandidates${extensionCandidateIdx}_nominationHistoriesTemp[${nominationHistoryTempIdx}]_${extensionCandidate.nominationHistoriesTemp.length}`"
                        v-model="nominationHistoryTemp.value"
                        :vendibles="nominations"
                        :users="users"
                        :hide-vending-contributors="true"
                        class="mb-4"
                        @update:vendibleId="nominationHistoryTemp.value.vendibleId = $event"
                        @update:sellingPrice="nominationHistoryTemp.value.sellingPrice = $event"
                        @update:taxCharge="nominationHistoryTemp.value.taxCharge = $event"
                        @remove="removeNominationHistoryTemp(extensionCandidateIdx, nominationHistoryTemp.value)"
                      />
                      <v-card
                        elevation="0"
                        class="d-flex align-center justify-center"
                      >
                        <v-btn
                          fab
                          icon
                          large
                          :ripple="false"
                          color="primary"
                          @click="
                            extensionCandidate.nominationHistoriesTemp.push(
                              buildVendingHistoryTemp({ vendibleType: 'Nomination' })
                            )"
                        >
                          <v-icon>
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </order-card>

            <total-display
              :subtotal="getSubtotal(vendingHistoriesTempOfArray[extensionCandidateIdx])"
              :tax="getTotalTax(vendingHistoriesTempOfArray[extensionCandidateIdx])"
              :total="getTotal(extensionCandidateIdx)"
              :paid-in-advance-amount="paidInAdvanceAmount"
              :title="'概算'"
              :total-msg="'注文後のお会計'"
              class="mt-3 mb-10 px-2"
            >
              <template #below-title>
                <v-btn
                  :ripple="false"
                  color="primary"
                  @click="addToCart(extensionCandidateIdx)"
                >
                  <v-icon left>
                    {{ icons.mdiCartPlus }}
                  </v-icon>
                  選択
                </v-btn>
              </template>
            </total-display>
          </div>

          <print-btn
            subject="概算伝票"
            :block="true"
            :print="printEstimatedSlip"
          >
            <template #append>
              <Preview
                :slip-svg="slipSvg"
                class="mt-1"
              />
            </template>
          </print-btn>
        </div>

        <div v-else>
          <v-skeleton-loader
            v-if="isInitializing"
            height="160"
            type="card"
          />

          <p
            v-else
            class="ma-0 ml-2 pa-0 text-sm text--secondary"
          >
            延長候補はありません
          </p>
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  ref,
  computed,
  inject,
  watch,
  onUnmounted,
  getCurrentInstance,
  onMounted,
} from '@vue/composition-api'
import {
  mdiCogOutline,
  mdiAttachment,
  mdiPlus,
  mdiLightbulbOutline,
  mdiReload,
  mdiCartPlus,
} from '@mdi/js'
import {
  map,
  each,
  cloneDeep,
  filter,
  find,
  findIndex,
  flatMap,
  isEqual,
  isEmpty,
  includes,
  chain,
  sum,
  sumBy,
  update,
  compact,
  every,
  first,
} from 'lodash'
import { useEstimatedSlipPrint } from '@yonarezi/yonarezi3.slip-print'
import { removePortFromIpAddress } from '@/utils/removePortFromIpAddress'
import TableApi from '@/api/waiter/Table'
import ExtensionProposalApi from '@/api/v2/order/ExtensionProposal'
import useVendingHistory from '@/views/composable/useVendingHistory'
import useDateFormat from '@/views/composable/useDateFormat'
import useCurrentData from '@/views/composable/useCurrentData'
import OrderCard from '@/views/components/table/OrderCard/OrderCard.vue'
import NominationDisplay from '@/views/components/table/NominationDisplay.vue'
import TotalDisplay from '@/views/components/util/TotalDisplay.vue'
import PrintBtn from '@/views/components/util/PrintBtn.vue'
import Preview from '@/views/components/printer/Preview.vue'

export default {
  components: {
    OrderCard,
    NominationDisplay,
    TotalDisplay,
    PrintBtn,
    Preview,
  },
  props: {
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tableNumber: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tablePrefix: {
      type: String,
      default: '',
    },
    tableSuffix: {
      type: String,
      default: '卓',
    },
    startAt: {
      type: String,
      required: true,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
    customerCount: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    total: {
      type: [Number, String],
      required: true,
    },
    paidInAdvanceAmount: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const courses = inject('courses', [])
    const nominations = inject('nominations', [])
    const users = inject('users', [])
    const printer = inject('printer', null)
    const preDefinedVendingHistoriesTemp = inject('preDefinedVendingHistoriesTemp', [])

    const { hhmm } = useDateFormat()
    const { currentClub } = useCurrentData()

    const opener = ref(true)
    const extensionCandidates = ref([])
    const isInitializing = ref(true)
    const estimatedTotals = ref([])
    const extensionProposals = ref([])

    const {
      buildVendingHistoryTemp,
      setVendingContributionsAttributes,
      buildVendingHistoriesToSubmit,
    } = useVendingHistory()

    const hasExtensionCandidateCourses = computed(() => {
      return extensionProposals.value.length > 0
    })

    const vendingHistoriesTempOfArray = computed(() => {
      return map(extensionCandidates.value, extensionCandidate => {
        const vendingHistoriesTemp = [
          extensionCandidate.courseHistoryTemp,
          ...extensionCandidate.nominationHistoriesTemp,
        ]

        return vendingHistoriesTemp
      })
    })

    const vendingHistoriesToSubmitOfArray = computed(() => {
      return map(vendingHistoriesTempOfArray.value, arr => {
        return buildVendingHistoriesToSubmit(arr)
      })
    })

    // NOTE: TAX計算をさせるwatch対象
    const taxEstimationTargets = computed(() => {
      return flatMap(vendingHistoriesTempOfArray.value, arr => {
        return flatMap(arr, o => {
          const { sellingPrice, taxCharge } = o.value

          return [sellingPrice, taxCharge]
        })
      })
    })

    // NOTE: 大計計算をさせるwatch対象
    const totalEstimationTargets = computed(() => {
      // NOTE TAXの計算結果たち（注文内容の変化に応じてtax計算されるので）
      const estimatedTaxPrices = flatMap(vendingHistoriesTempOfArray.value, arr => {
        return flatMap(arr, o => {
          return o.estimatedTaxPrice
        })
      })

      // NOTE 注文する内容の個数
      const len = sum(map(vendingHistoriesToSubmitOfArray.value, arr => arr.length))

      return [...estimatedTaxPrices, len]
    })

    // NOTE: 現在選択中(このcompのOrderCardから選択している最中)の指名ユーザーからNominationDisplayを表示させる
    const currentNominationsTempOfArray = computed(() => {
      return map(extensionCandidates.value, extensionCandidate => {
        const validNominationHistoriesTemp = filter(
          extensionCandidate.nominationHistoriesTemp,
          nominationHistoryTemp => {
            return every(nominationHistoryTemp.value.vendibleRecipientsAttributes, 'userId')
              && nominationHistoryTemp.value.vendibleId
          },
        )

        return chain(validNominationHistoriesTemp)
          .map(nominationHistoryTemp => {
            // NOTE:
            // vendibleRecipientsは複数想定だが指名のときは一つだけしか基本的に持たない(入力のUIもそうなってる)
            // そのためfirstをつかっている
            const nominee = find(users.value, user => Number(user.id) === Number(first(nominationHistoryTemp.value.vendibleRecipientsAttributes)?.userId))
            const targetNomination = find(nominations.value, nomination => Number(nomination.id) === Number(nominationHistoryTemp.value.vendibleId))

            if (!(nominee && targetNomination)) return null

            const vendingHistoryMock = {
              id: Math.random(),
              meta: {
                color: targetNomination.attributes?.color,
              },
              attributes: {
                vendibleRecipients: {
                  data: [{
                    attributes: {
                      recipient: { data: nominee },
                    },
                  }],
                },
                vendibleId: targetNomination.id,
              },
            }

            return vendingHistoryMock
          })
          .compact()
          .value()
      })
    })

    // NOTE: 取得した延長候補からvendingHistoriesTempをつくる
    const initVendingHistoriesTemp = async incomingExtensionProposals => {
      extensionCandidates.value = incomingExtensionProposals.map(extensionProposal => {
        const courseHistoryTemp = buildVendingHistoryTemp({
          vendibleId: extensionProposal.course.vendibleId,
          vendibleType: extensionProposal.course.vendibleType,
          sellingPrice: extensionProposal.course.sellingPrice,
          size: extensionProposal.course.size,
          taxCharge: extensionProposal.course.taxCharge,
        })

        const nominationHistoriesTemp = extensionProposal.nominations.map(nomination => {
          return buildVendingHistoryTemp({
            vendibleId: nomination.vendibleId,
            vendibleType: nomination.vendibleType,
            sellingPrice: nomination.sellingPrice,
            size: nomination.size,
            taxCharge: nomination.taxCharge,
            vendibleRecipientsAttributes: nomination.vendibleRecipientsAttributes,
          })
        })

        return { courseHistoryTemp, nominationHistoriesTemp }
      })
    }

    const removeNominationHistoryTemp = (extensionCandidateIdx, nominationHistoryTempValue) => {
      const targetNominationHistoriesTemp = extensionCandidates.value[extensionCandidateIdx].nominationHistoriesTemp
      const index = findIndex(targetNominationHistoriesTemp, { value: nominationHistoryTempValue })

      targetNominationHistoriesTemp.splice(index, 1)
    }

    const calcTax = async price => {
      const res = await TableApi.calcTax({
        tableId: props.tableId,
        price,
      })

      return res?.data
    }

    const calcTotal = async orderPrice => {
      const res = await TableApi.calcTotal({
        tableId: props.tableId,
        orderPrice,
      })

      return res?.data
    }

    const getSubtotal = vendingHistoriesTemp => {
      return sumBy(vendingHistoriesTemp, vendingHistoryTemp => {
        return vendingHistoryTemp.size * vendingHistoryTemp.value.sellingPrice
      })
    }

    const getTotalTax = vendingHistoriesTemp => {
      return sumBy(vendingHistoriesTemp, vendingHistoryTemp => {
        return vendingHistoryTemp.size * vendingHistoryTemp.estimatedTaxPrice
      })
    }

    const getTotal = index => {
      const estimatedTotalObj = find(estimatedTotals.value, o => o.extensionCandidateIdx === index)

      return estimatedTotalObj?.amount
    }

    const addToCart = index => {
      preDefinedVendingHistoriesTemp.value = []
      preDefinedVendingHistoriesTemp.value = vendingHistoriesTempOfArray.value[index]

      emit('order')
    }

    // NOTE: YONAREZI 3
    const estimatedSlipOptions = computed(() => {
      const estimationTargets = [...estimatedTotals.value]
        .sort((a, b) => a.amount - b.amount)
        .map(estimatedTotalObj => {
          const extensionCandidate = extensionCandidates.value[estimatedTotalObj.extensionCandidateIdx]
          const vendingHistoriesTemp = compact([extensionCandidate.courseHistoryTemp, ...(extensionCandidate.nominationHistoriesTemp || [])])

          return {
            estimatedTotal: estimatedTotalObj.amount,
            estimatedTax: getTotalTax(vendingHistoriesTemp),
            vendingHistoriesTemp,
          }
        })

      return {
        ipAddress: removePortFromIpAddress(currentClub.value.printIpAddress),
        startAt: hhmm(props.startAt),
        expireAt: props.expireAt ? hhmm(props.expireAt) : '',
        tableCode: [props.tablePrefix, props.tableNumber, props.tableSuffix].join(''),
        customerCount: props.customerCount.toLocaleString(),
        total: props.total,
        paidInAdvanceAmount: props.paidInAdvanceAmount,
        vendibles: compact([...(courses.value || []), ...(nominations.value || [])]),
        recipients: users.value,
        estimationTargets,
        nominationAlias: currentClub.value.nominationAlias,
        courseAlias: currentClub.value.courseAlias,
        printer: printer.value,
      }
    })
    const { send: printEstimatedSlip, onReceive, svg: slipSvg } = useEstimatedSlipPrint(estimatedSlipOptions)
    onReceive.value = response => {
      if (!response.success) {
        vm.$toast.error(response)
      }
    }

    // NOTE: 親の客数変更に依存してsizeも変える
    watch(() => props.customerCount, (newVal, _prev) => {
      each(extensionCandidates.value, extensionCandidate => {
        // eslint-disable-next-line no-param-reassign
        extensionCandidate.courseHistoryTemp.size = newVal
      })
    })

    // NOTE: 販売貢献人をvendingHistoryTempに自動セットするwatch
    watch(() => cloneDeep(vendingHistoriesTempOfArray.value), (_newVal, _prevVal) => {
      each(vendingHistoriesTempOfArray.value, vendingHistoriesTemp => {
        setVendingContributionsAttributes({
          targetVendingHistoriesTemp: vendingHistoriesTemp,
        })
      })
    })

    // NOTE: 概算を出すために税サを計算
    watch(() => cloneDeep(taxEstimationTargets.value), (newVal, prevVal) => {
      if (isEqual(newVal, prevVal)) return

      each(extensionCandidates.value, extensionCandidate => {
        // NOTE: 延長コースの税サ
        if (extensionCandidate.courseHistoryTemp.value.taxCharge === 'normal') {
          calcTax(extensionCandidate.courseHistoryTemp.value.sellingPrice).then(taxPrice => {
            update(extensionCandidate, 'courseHistoryTemp.estimatedTaxPrice', () => taxPrice)
          })
        } else {
          update(extensionCandidate, 'courseHistoryTemp.estimatedTaxPrice', () => 0)
        }

        // NOTE 付帯指名の税サ
        each(extensionCandidate.nominationHistoriesTemp, nominationHistoryTemp => {
          if (nominationHistoryTemp.value.taxCharge === 'normal') {
            calcTax(nominationHistoryTemp.value.sellingPrice).then(taxPrice => {
              update(nominationHistoryTemp, 'estimatedTaxPrice', () => taxPrice)
            })
          } else {
            update(nominationHistoryTemp, 'estimatedTaxPrice', () => 0)
          }
        })
      })
    })

    // NOTE: 概算を出すために大計を計算
    watch(() => cloneDeep(totalEstimationTargets.value), (newVal, prevVal) => {
      if (isEmpty(newVal)) return
      if (includes(newVal, null)) return
      if (isEqual(newVal, [0])) return
      if (isEqual(newVal, prevVal)) return

      estimatedTotals.value = []

      each(extensionCandidates.value, (extensionCandidate, index) => {
        const vendingHistoriesTemp = [extensionCandidate.courseHistoryTemp, ...extensionCandidate.nominationHistoriesTemp]

        const rawOrderTotal = chain(vendingHistoriesTemp)
          .map(vendingHistoryTemp => {
            const sellingPrice = Number(vendingHistoryTemp.value.sellingPrice)
            const estimatedTaxPrice = Number(vendingHistoryTemp.estimatedTaxPrice)

            return (sellingPrice + estimatedTaxPrice) * vendingHistoryTemp.size
          })
          .sum()
          .value()

        calcTotal(rawOrderTotal).then(total => {
          estimatedTotals.value.push({
            extensionCandidateIdx: index,
            amount: total,
          })
        })
      })
    })

    const resetComp = () => {
      opener.value = 0
      extensionProposals.value = []
      extensionCandidates.value = []
      isInitializing.value = true
      estimatedTotals.value = []
    }
    onUnmounted(() => resetComp())

    onMounted(async () => {
      const res = await ExtensionProposalApi.getExtensionProposals({ tableId: props.tableId })
      if (res?.data) {
        extensionProposals.value = res.data
        initVendingHistoriesTemp(extensionProposals.value)
      }
      isInitializing.value = false
    })

    return {
      // inject
      courses,
      nominations,
      users,
      preDefinedVendingHistoriesTemp,

      // data
      opener,
      extensionProposals,
      extensionCandidates,
      isInitializing,
      estimatedTotals,

      // computed
      hasExtensionCandidateCourses,
      currentNominationsTempOfArray,
      taxEstimationTargets,
      totalEstimationTargets,
      vendingHistoriesTempOfArray,
      vendingHistoriesToSubmitOfArray,
      slipSvg,

      // methods
      buildVendingHistoryTemp,
      removeNominationHistoryTemp,
      getSubtotal,
      getTotalTax,
      getTotal,
      addToCart,
      printEstimatedSlip,

      icons: {
        mdiCogOutline,
        mdiAttachment,
        mdiPlus,
        mdiLightbulbOutline,
        mdiReload,
        mdiCartPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
