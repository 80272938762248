<template>
  <v-card
    v-if="courses.length > 0"
    elevation="0"
  >
    <v-card-title>
      <p class="pa-0 ma-0 text--secondary text-sm">
        {{ currentClub.courseAlias }}
      </p>
      <v-spacer />
      <v-btn
        icon
        fab
        :ripple="false"
        @click="opener = !opener"
      >
        <v-icon>
          {{ icons.mdiCogOutline }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <v-card-text v-show="!opener">
        <v-timeline
          v-if="courseHistories.length > 0"
          dense
          class="timeline-custom-dense timeline-custom-dots mb-3"
        >
          <v-timeline-item
            small
            :color="!expireAt || Date.parse(expireAt) - (new Date()) > 0 ? 'primary' : 'error'"
          >
            <div class="d-flex align-center justify-space-between">
              <p
                v-if="courseOverview.attributes.persistenceReport
                  && courseOverview.attributes.persistenceReport.data
                  && courseOverview.attributes.persistenceReport.data.attributes
                  && courseOverview.attributes.persistenceReport.data.attributes.startAt"
                class="text-sm pa-0 ma-0"
              >
                {{ hhmm(courseOverview.attributes.persistenceReport.data.attributes.startAt) }}
              </p>

              <p class="pa-0 ma-0 text-sm pr-4">
                {{ courseOverview.attributes.vendibleName }}
              </p>
            </div>

            <table-timer
              :expire-at="expireAt"
              class="ma-0 pa-0 mt-3 pr-12"
            />
          </v-timeline-item>
        </v-timeline>

        <div
          v-else
          class="ml-2"
        >
          ...
        </div>
      </v-card-text>
    </v-expand-transition>

    <v-expand-transition>
      <v-card-text v-show="opener">
        <v-timeline
          dense
          class="timeline-custom-dense timeline-custom-dots mb-3"
        >
          <v-timeline-item
            v-for="(courseHistory, courseHistoryIdx) in courseHistories"
            :key="`courseHistories[${courseHistoryIdx}]`"
            small
            :color="!courseHistory.releaseAt || Date.parse(courseHistory.releaseAt) - (new Date()) > 0 ? 'primary' : 'error'"
          >
            <div class="d-flex align-center justify-space-between">
              <p class="text-sm pa-0 ma-0">
                {{ hhmm(courseHistory.startAt) }}
              </p>

              <p class="pa-0 ma-0 text-sm">
                {{ courseHistory.vendibleName }}
                <span>× {{ courseHistory.size }}</span>
              </p>
            </div>

            <!-- コースの時間変更 -->
            <div v-if="isChanging === courseHistoryIdx">
              <!-- eslint-disable vue/v-on-event-hyphenation -->
              <persistence-report-card
                v-for="(courseHistoryTemp, courseHistoryTempIdx) in courseHistoriesTemp"
                :key="`courseHistoriesTemp[${courseHistoryTempIdx}]`"
                :value="courseHistoryTemp.persistenceReportAttributes"
                class="my-5"
                @update:startAt="courseHistoryTemp.persistenceReportAttributes.startAt = $event"
                @update:releaseAt="courseHistoryTemp.persistenceReportAttributes.releaseAt = $event"
              />
              <div class="w-full d-flex justify-space-around align-center px-2">
                <v-btn
                  color="primary"
                  :ripple="false"
                  :loading="isSubmitting"
                  :disabled="!isValidPersistenceReportsToSubmit || isSubmitting"
                  @click="updateCourseHistories"
                >
                  更新
                </v-btn>
                <v-btn
                  :ripple="false"
                  color="secondary"
                  text
                  small
                  class="ml-4"
                  @click="cancelChanging"
                >
                  キャンセル
                </v-btn>
              </div>
            </div>

            <div
              v-else
              class="d-flex align-center mt-4 mb-4"
            >
              <table-timer
                :id="courseHistory.id"
                :expire-at="courseHistory.releaseAt"
                class="ma-0 pa-0 mr-1"
              />

              <v-btn
                v-if="courseHistory.startAt && courseHistory.releaseAt"
                :ripple="false"
                small
                right
                icon
                @click="changePersistenceReports(courseHistoryIdx, courseHistory.actualData)"
              >
                <v-icon small>
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  inject,
  ref,
  computed,
  watch,
  getCurrentInstance,
  onUnmounted,
} from '@vue/composition-api'
import {
  each,
  every,
  chain,
} from 'lodash'
import { mdiCogOutline, mdiPencilOutline } from '@mdi/js'
import useCurrentData from '@/views/composable/useCurrentData'
import useDateFormat from '@/views/composable/useDateFormat'
import usePersistenceReport from '@/views/composable/usePersistenceReport'
import VendingHistoryApi from '@/api/waiter/VendingHistory'
import TableTimer from '@/views/components/table/TableTimer.vue'
import PersistenceReportCard from '@/views/components/table/PersistenceReportCard.vue'

export default {
  components: {
    TableTimer,
    PersistenceReportCard,
  },
  props: {
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    expireAt: {
      type: String,
      required: false,
      default: null,
    },
    courseOverview: {
      // NOTE: 中身はその卓で一番終わるまでの時間が長いコースのvendingHistory
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const vendingHistories = inject('vendingHistories', [])
    const courses = inject('courses', [])

    const courseHistoriesTemp = ref([])
    const opener = ref(false)
    const isChanging = ref(false)
    const isSubmitting = ref(false)

    const { currentClub } = useCurrentData()
    const { hhmm } = useDateFormat()
    const {
      isValid: isValidPersistenceReport,
      buildPersistenceReportTemp,
    } = usePersistenceReport()

    const isValidPersistenceReportsToSubmit = computed(() => {
      return every(courseHistoriesTemp.value, courseHistoryTemp => isValidPersistenceReport(courseHistoryTemp.persistenceReportAttributes))
    })

    // NOTE: セット(60分) x 3 とかやらなきゃなので
    const courseHistories = computed(() => {
      return chain(vendingHistories.value)
        .filter(vendingHistory => vendingHistory.attributes.vendibleType === 'Course')
        .orderBy(['attributes.persistenceReport.data.attributes.startAt'], ['desc'])
        .groupBy(courseHistory => {
          const { vendibleId, vendibleName } = courseHistory.attributes
          const { startAt, releaseAt } = courseHistory.attributes.persistenceReport.data?.attributes || {}
          if (!(startAt && releaseAt)) return null

          return `${vendibleId}${vendibleName}${hhmm(startAt)}${hhmm(releaseAt)}`
        })
        .mapValues((courseHistoriesGrouped, key) => {
          const { vendibleId, vendibleName } = courseHistoriesGrouped[0]?.attributes
          const { startAt, releaseAt } = courseHistoriesGrouped[0]?.attributes?.persistenceReport?.data?.attributes || {}

          return {
            key,
            vendibleId,
            vendibleName,
            startAt,
            releaseAt,
            size: courseHistoriesGrouped.length,
            actualData: courseHistoriesGrouped,
          }
        })
        .values()
        .value()
    })

    const cancelChanging = () => {
      isChanging.value = false
      courseHistoriesTemp.value = []
    }

    const resetComp = () => {
      opener.value = false
      isSubmitting.value = false
      cancelChanging()
    }

    const updateCourseHistories = async () => {
      isSubmitting.value = true

      const res = await VendingHistoryApi.updateVendingHistories({
        tableId: props.tableId,
        vendingHistories: courseHistoriesTemp.value,
      })

      if (res?.data) {
        emit('updated:vending-histories', res.data.vendingHistories.data)
        emit('updated:table', res.data.table.data)

        vm.$toast.success(`${currentClub.value.courseAlias}の時間を更新しました`)
      }

      isSubmitting.value = false
      cancelChanging()
    }

    // NOTE: 制限時間を表現するための持続レポート（PersistenceReport）を変更する
    const changePersistenceReports = (index, courseHistoriesData) => {
      // NOTE: PersistenceReportはVendingHistoryがhas_oneでもってるのと、VendingHistoriesのnestedで変更した方がクライアント側で変更を検知するのに楽なので
      // NOTE: 本当はuseVendingHistoryを使いたっ方けど、idだけもつとかが今の仕様だとできなかったのでここだけハードコーディング（TODO）
      courseHistoriesTemp.value = []

      isChanging.value = index

      each(courseHistoriesData, courseHistory => {
        const courseHistoryTemp = { id: courseHistory.id }

        const persistenceReport = courseHistory.attributes.persistenceReport.data

        const {
          vendingHistoryId,
          startAt,
          releaseAt,
        } = persistenceReport.attributes

        const persistenceReportTemp = buildPersistenceReportTemp({
          id: persistenceReport.id,
          vendingHistoryId,
          startAt,
          releaseAt,
        })

        courseHistoryTemp.persistenceReportAttributes = persistenceReportTemp
        courseHistoriesTemp.value.push(courseHistoryTemp)
      })
    }

    // NOTE: 閉めたらreset
    watch(() => opener.value, (newBool, _prev) => {
      if (!newBool) resetComp()
    })

    onUnmounted(() => resetComp())

    return {
      courses,
      courseHistoriesTemp,
      opener,
      isChanging,
      isSubmitting,

      isValidPersistenceReportsToSubmit,
      courseHistories,

      updateCourseHistories,
      changePersistenceReports,
      cancelChanging,

      hhmm,

      icons: {
        mdiCogOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
